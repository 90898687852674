import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";

const PolicyPage = ({ data: { site } }) => {
    return (
        <Layout>
            <Helmet>
                <title>Privacy Policy</title>
                <meta
                    name="description"
                    content={"Policy page of " + site.siteMetadata.description}
                />
            </Helmet>
            <div className="bg-white">

                <div className="py-16 xl:py-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
                    <div className="max-w-max lg:max-w-7xl mx-auto">
                        <div className="relative z-10 mb-8 md:mb-2 md:px-6">
                            <div className="text-base max-w-prose lg:max-w-none">
                                <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Policy</h2>
                                <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Policy and privacy information</p>
                            </div>
                        </div>
                        <div className="relative">
                            <div className="relative md:bg-white md:p-6">
                                <div className="lg:grid lg:grid-cols-1 lg:gap-6">
                                    <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
                                        <p>Tim Benstead Associates Limited (TBAL) will always respect your privacy and will only use your information for specified and lawful purposes as provided for under the General Data Protection Regulation (GDPR). We will use and handle your information responsibly and will take all appropriate organisational and technical measures to safeguard your information from accidental or unlawful destruction, loss, alteration, unauthorised disclosure or access.</p>

                                    </div>
                                    <div className="mt-6 prose prose-indigo prose-lg text-gray-500 lg:mt-0">

                                        <p className="font-semibold">What information we collect about you</p>

                                        <p>In order to provide you with our services we may collect some of the following information about you:</p>

                                        <ul>
                                            <li>Name</li>
                                            <li>Postal address</li>
                                            <li>Name of business (employer)</li>
                                            <li>Postal address of business (employer)</li>
                                            <li>Contact telephone number</li>
                                            <li>Contact email address</li>
                                        </ul>

                                        <p className="font-semibold">How your information will be used?</p>

                                        <p>Your information will be used to provide the services you have instructed and added to our systems so that we can do this.</p>

                                        <p className="font-semibold">With whom we might share your information</p>

                                        <p>We will not share your data with any third party unless instructed to do so.</p>

                                        <p className="font-semibold">How long we will keep your information?</p>

                                        <p>We will not hold your personal information indefinitely and periodically we will review whether it is still appropriate for us to retain this information. If you wish us to delete your information from our systems at any time you can request us to do so.</p>

                                        <p className="font-semibold">Lawful basis for processing</p>

                                        <p>We will only process your information where it is necessary to support the legitimate interests of our business.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    );
};
export default PolicyPage;
export const pageQuery = graphql`
  query PolicyPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
